<template>
  <div>
    <default-app-bar>
      <template v-slot:title>
        <div class="text-h3 mr-2">Panel principal</div>
      </template>
    </default-app-bar>

    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="form-col"
          style="border: 1px solid white"
        >
          <v-toolbar color="primary" dark dense
            ><div class="text-h5">Información clientes</div></v-toolbar
          >
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-simple-table v-if="resumen">
            <template v-slot:default>
              <thead>
                <tr class="totals">
                  <td class="text-left"></td>
                  <td class="text-left"># Clientes</td>
                  <td class="text-right">x Cobrar</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sin Deuda</td>
                  <td>{{ resumen.clientes.total_clientes_sin_deuda }}</td>
                  <td class="text-right">{{ 0 }}</td>
                </tr>
                <tr>
                  <td>Con Deuda No Vencida</td>
                  <td>
                    {{ resumen.clientes.total_clientes_deuda_no_vencida }}
                  </td>
                  <td class="text-right">
                    {{
                      $filters.currency(resumen.clientes.total_deuda_no_vencida)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Con Deuda Vencida</td>
                  <td>{{ resumen.clientes.total_clientes_deuda_vencida }}</td>
                  <td class="text-right">
                    {{
                      $filters.currency(resumen.clientes.total_deuda_vencida)
                    }}
                  </td>
                </tr>
                <tr class="totals">
                  <td>Total</td>
                  <td>{{ resumen.clientes.total_clientes }}</td>
                  <td class="text-right">
                    {{ $filters.currency(resumen.clientes.total_xpagar) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="form-col mt-6"
          style="border: 1px solid white"
        >
          <v-divider></v-divider>
          <GChart
            type="PieChart"
            :data="getInfoPieClientes()"
            :options="getOptionsPieClientes()"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="form-col"
          style="border: 1px solid white"
        >
          <v-divider></v-divider>
          <v-toolbar color="primary" dark dense
            ><div class="text-h5">Distribución cartera</div></v-toolbar
          >
          <v-simple-table v-if="resumen">
            <template v-slot:default>
              <thead>
                <tr class="totals">
                  <td class="text-left"></td>
                  <td class="text-right">x Cobrar</td>
                  <td class="text-right">%</td>
                </tr>
              </thead>
              <tbody>
                <template v-for="(t, index) in estadoCartera.chartData">
                  <tr v-if="index > 0">
                    <td class="text-left">{{ t[0] }}</td>
                    <td class="text-right">{{ $filters.currency(t[1]) }}</td>
                    <td class="text-right">
                      {{ $filters.ceil10(getPorcentaje(t[1])) }}
                    </td>
                  </tr>
                </template>
                <tr class="totals">
                  <td class="text-left">Total</td>
                  <td class="text-right">
                    {{ $filters.currency(resumen.estadoCarteraTotal) }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="form-col mt-6"
          v-if="resumen"
          style="border: 1px solid white"
        >
          <v-divider></v-divider>
          <GChart
            type="PieChart"
            :data="estadoCartera.chartData"
            :options="estadoCartera.chartOptions"
          />
        </v-col>
        <!--v-col cols="12" sm="6" class="form-col" v-if="resumen">
            <GChart type="PieChart" :data="getInfoPieClientes()" :options="getOptionsPieClientes()" />
           </v-col-->
        <v-col
          cols="12"
          sm="12"
          class="form-col mt-6"
          v-if="resumen"
          style="border: 1px solid white"
        >
          <v-divider></v-divider>

          <GChart
            type="PieChart"
            :data="resumen.carteraEdades"
            :options="resumen.optionsChartCarteraEdades"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          class="form-col"
          v-if="resumen && resumen.evolucion"
          style="border: 1px solid white"
        >
          <GChart
            type="LineChart"
            :data="resumen.evolucion"
            :options="resumen.optionsChartEvolucion"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          class="form-col"
          style="border: 1px solid white"
        >
          <v-divider></v-divider>
          <v-toolbar color="primary" dark dense
            ><div class="text-h5">Detalle Mensual</div></v-toolbar
          >
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-simple-table v-if="resumen" class="mb-4">
            <template v-slot:default>
              <thead>
                <tr class="totals">
                  <th class="text-left"></th>
                  <td class="text-right">Ventas</td>
                  <td class="text-right">Acumulado</td>

                  <td class="text-right">Cobranza</td>
                  <td class="text-right">Acumulado</td>

                  <td class="text-left">Flujo</td>
                  <td class="text-right">Saldo</td>
                </tr>
                <tr class="totals">
                  <td class="text-right">Total</td>
                  <th class="text-right"></th>
                  <th class="text-right"></th>

                  <th class="text-right"></th>
                  <th class="text-right"></th>

                  <th class="text-right"></th>
                  <th class="text-right"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in resumenAcumulado">
                  <td class="text-right">{{ item.periodo }}</td>
                  <td class="text-right">
                    {{ $filters.currency(item.ventas) }}
                  </td>
                  <td class="text-right">
                    {{ $filters.currency(item.vacumulado) }}
                  </td>
                  <td class="text-right">
                    {{ $filters.currency(item.ingresos) }}
                  </td>

                  <td class="text-right">
                    {{ $filters.currency(item.iacumulado) }}
                  </td>
                  <td class="text-right">
                    {{ $filters.currency(item.flujo) }}
                  </td>

                  <td class="text-right">
                    {{ $filters.currency(item.saldo) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>

    <!--div v-if="tmpl" :key="renderKey">
            
            <component :is="getComponent()" :info="info"  ></component>
      </div-->
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  data() {
    return {
      loading: true,
      resumen: null,
      user: null,
      form: {
        bus_person_cliente_id: null,
        bus_convenio_admin_cartera_id: null,
      },
      resumenAcumulado: [],
      estadoCartera: {
        chartData: [
          ["Estado", "Valor"],
          ["Administrativa", 1],
          ["Dificíl Cobro", 0],
          ["Prejurídica", 0],
          ["Jurídica", 0],
        ],
        chartOptions: {
          title: "Distribución de cartera",
          subtitle: "",
          height: 300,
          colors: ["green", "blue", "#e6cf25", "#f55e27", "red"],
          // pieSliceText: 'value'
        },
      },
      config: {
        id: { label: "Crédito", name: "id" },
        bus_person_cliente_id: {
          label: "Cliente",
          name: "bus_person_cliente_id",
          url: "api/default/bus_person/search",
        },
        bus_convenio_admin_cartera_id: {
          label: "Convenio",
          url: "api/admin/person/search-inver",
          name: "bus_convenio_admin_cartera_id",
        },
      },
      renderKey: 1,
      info: null,
      tmpl: "",
    };
  },
  methods: {
    getOptionsPieClientes() {
      return {
        title: "Información clientes",
        subtitle: "",
        height: 300,
        colors: ["blue", "green", "red"],
        // pieSliceText: 'value'
      };
    },
    getInfoPieClientes() {
      // this.resumen = response.data.resumen;
      if (!this.resumen) {
        return [
          ["Estado", "Valor"],
          ["Sin Deuda", 1],
          ["Con Deuda No Vencida", 0],
          ["Con Deuda Vencida", 0],
        ];
      } else {
        return [
          ["Estado", "Valor"],
          ["Sin Deuda", 0],
          [
            "Con Deuda No Vencida",
            this.resumen.clientes.total_deuda_no_vencida,
          ],
          ["Con Deuda Vencida", this.resumen.clientes.total_deuda_vencida],
        ];
      }
    },
    getDataTable(data) {
      //return google.visualization.arrayToDataTable(data);
      return data;
    },
    getPorcentaje(v) {
      if (!v) {
        v = 0;
      }
      let total = this.getTotalDistribucion(this.estadoCartera.chartData);
      if (!total) {
        total = 1;
      }
      return (v / total) * 100;
    },
    getTotalDistribucion(data) {
      // console.log(data)
      let total = 0;
      for (const i in data) {
        if (i > 0) {
          if (!data[i][1]) {
            data[i][1] = 0;
          }
          total += data[i][1];
        }
      }
      return total;
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    fetchInfo() {
      this.$http
        .post("api/admin/resumen-cartera", { data: {} })
        .then((response) => {
          // console.log(response)
          this.loading = false;
          if (response.success) {
            this.resumen = response.resumen;
            this.estadoCartera.chartData = this.resumen.estadoCartera;
            this.getResultadosAnho();
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getResultadosAnho() {
      let data = this.resumen.evolucion.chartData;
      //let dataReversed = data.reverse();
      data.shift();
      const resume = [];
      let vacumulado = 0;
      let iacumulado = 0;
      for (const d of data) {
        vacumulado += parseFloat(d[1]);
        iacumulado += parseFloat(d[2]);
        resume.push({
          periodo: d[0],
          ventas: d[1],
          vacumulado: vacumulado,
          ingresos: d[2],
          iacumulado: iacumulado,
          flujo: d[1] - d[2],
          saldo: vacumulado - iacumulado,
        });
      }

      this.resumenAcumulado = resume.reverse();
    },
  },
  created() {
    this.fetchInfo();
    let u = localStorage.getItem("user");
    this.user = JSON.parse(u);
    //console.log(this.$store.user)
  },
};
</script>
